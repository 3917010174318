<template>
  <div>
      <el-form-item prop="EmployeeList" :rules="EmployeeListrules" label="适用员工：" class = "staffError" style="margin:0px 0px 5px 0px;">
        <div class="flexRow">
          <el-tag type="info" plain class="flexRow" v-for="(item,i) in EmployeeList" :key="item.Id" v-if="i<4"  style="margin:0px 10px 5px 0px">
            <img v-if="item.WxHeadUrl" :src="item.WxHeadUrl" style="width:20px;height:20px;border-radius:3px" alt=""/>
            <i v-else class="el-icon-user"></i>
            <span style="margin-left:5px">{{item.EmployeeName}}</span>
          </el-tag>
          <div class="flexRow" style="margin:0px 10px" v-if="EmployeeList && EmployeeList.length">
            <el-button type="text" @click="choosestaffshow=true">
              <i class="el-icon-arrow-down" style="color:#999999"></i>
            </el-button>
          </div>
          <el-button type="text" @click="choosestaffshow=true">{{EmployeeList && EmployeeList.length ? "修改" : "选择"}}适用员工</el-button>
        </div>
        <div class="garyFont">
          客户在商城内点击配置了活码的入口时，企业微信将推送已选中员工的企业微信二维码。根据企业微信规定，每次最多选择100个员工
        </div>
        <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnt8DZhXslOu9KrdmNE2oLDh" type="primary" target="_blank"
         style="line-height:1.3;margin-top:-20px" :underline="false">有的员工在员工管理列表里存在，为什么在活动里查询不到？</el-link>
      </el-form-item>
      <staffdialog :Visible="choosestaffshow" :incomingchecklist="EmployeeList" @close = "closechoosestaff"
       @confirm = "getstafflits" :Id="Id"></staffdialog>
  </div>
</template>

<script>
import staffdialog from "./staffdialog"
export default {
  components: {
    staffdialog
  },
  props: {
    Id:{
      type:[Number,String],
      default:0
    },
    EmployeeList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    EmployeeListrules:{
      type:Array,
      default:()=>{
        return []
      }
    },
  },
  data () {
    return {
      choosestaffshow:false,
    }
  },
  created () {

  },
  methods: {
    closechoosestaff(){
      this.choosestaffshow = false
    },
    getstafflits(list){
      if(list.length>100){
        this.$message.error('每场活动最多选择100个员工')
      }else if(list<=0){
        this.$message.error('请选择员工')
      }else{
        // this.EmployeeList = list
        this.$emit('confirm',list)
        this.choosestaffshow = false
      }
    },
  }
};
</script>

<style lang = "less" scoped>
  .flexRow{
    display:flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
  }
  .garyFont{
    font-size: 12px;
    color: #999999;
    line-height: 2;
  }
  .staffError ::v-deep .el-form-item__error{
    top: 85% !important;
  }
</style>
