<template>
  <div>
    <el-form-item prop="Welcome" :rules="rule">
							<div class="edit-wraper">
								<div class="title">
									<div style="margin-right: 20px;">
										<el-link type="primary" @click="insertWxNickName">插入客户微信昵称</el-link>
									</div>
									<div>
										<el-link type="primary" @click="insertEmployeeName">插入员工姓名</el-link>
									</div>
								</div>
								<div class="edit-box" ref="editBox" @blur="editBlur" contenteditable="plaintext-only"
									@input="checkLength"></div>
								<!-- <div class="edit-box"></div> -->
								<div class="num">{{length}}/1000</div>
								<div class="link-wraper">
					
									<div class="file-list" v-if="fileList.length">
										<div class="file-item" v-for="(item, index) in fileList" :key="index">
											<div class="type">
												<span v-if="item.Type == 1">【图片】</span>
												<span v-if="item.Type == 2">【链接】</span>
												<span v-if="item.Type == 3">【小程序】</span>
											</div>
											<div class="txt">：{{item.LinkName}}</div>
											<div class="control">
												<div class="edit" v-if="item.Type != 1" @click="handleEditFile(item, index)">
													<i class="el-icon-edit"></i>
												</div>
												<el-upload :action="imgApi" v-else :before-upload="beforeUpload"
													:on-success="(res, file)=>{handleUploadSuccess(res, file, index)}"
													accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
													<div class="edit">
														<i class="el-icon-edit"></i>
													</div>
												</el-upload>
												<div class="close" @click="handleDeleteFile(index)">
													<i class="el-icon-close"></i>
												</div>
											</div>
										</div>
									</div>
					
									<el-popover placement="top" v-model="showPop" width="200" trigger="click" v-if="fileList.length < 9">
										<div class="control-wraper">
											<el-upload :action="imgApi" :before-upload="beforeUpload"
												:on-success="(res, file)=>{handleUploadSuccess(res, file, -1)}"
												accept="image/png,image/jpeg,image/jpg,image/gif" :show-file-list="false">
												<div class="item">
													<img src="@/assets/img/icon-tupian.png">
													<div>图片</div>
												</div>
											</el-upload>
											<div class="item" @click="handleAddLink">
												<img src="@/assets/img/icon-lianjie.png">
												<div>链接</div>
											</div>
											<div class="item" @click="handleAddMiniProgram">
												<img src="@/assets/img/icon-xiaochengxu.png">
												<div>小程序</div>
											</div>
										</div>
										<el-link type="primary" slot="reference">+添加图片/链接/小程序
										</el-link>
									</el-popover>
								</div>
							</div>
		</el-form-item>
						
		<div class="tips-box" style="max-width: 690px;margin-top:30px">
							<div class="tit">提示：</div>
							<div class="cont">
								<div>1.每条欢迎语最多可发送1条文字消息和9个附件</div>
								<div>2.欢迎语文本内容不可为空；若添加多个附件（图片/链接/小程序），客户将收到多条消息</div>
							</div>
		</div>


		<!-- 链接 -->
		<link-pop :visible="miniVisible" :defaultData="editData" @close="miniVisible=false" @change="miniChange"></link-pop>
			
		<!-- 小程序 -->
		<miniprogram-card :visible="cardVisible" :defaultData="editData" @close="cardVisible=false" @change="cardChange"></miniprogram-card>
  </div>
</template>

<script>
import config from "@/config"
import linkPop from './miniprogramLink.vue';
import miniprogramCard from './miniprogramCard.vue';
export default {
	components: {
		linkPop,
		miniprogramCard
	},
  props: {
    Welcome:{
      type:String,
      default:''
    },
		rule:{
			type:Array,
			default:()=>{
				return []
			}
		},
		fileList:{
			type:Array,
			default:()=>{
				return []
			}
		}
  },
  data () {
    return {
      imgApi: config.UPLOAD_IMG,
      showPop:false,
      length:0,
			miniVisible:false,
			cardVisible:false,
			editData: {},
    }
  },
	watch: {
		Welcome(){
			document.querySelector('.edit-box').innerHTML = this.Welcome.replace(/ #客户微信昵称# /g, ' <span style="color: #409EFF;">#客户微信昵称#</span> ').replace(/ #员工姓名# /g, ' <span style="color: #409EFF;">#员工姓名#</span> ');
			this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
		},
	},
	created () {
		this.$nextTick(()=>{
			document.querySelector('.edit-box').innerHTML = this.Welcome.replace(/ #客户微信昵称# /g, ' <span style="color: #409EFF;">#客户微信昵称#</span> ').replace(/ #员工姓名# /g, ' <span style="color: #409EFF;">#员工姓名#</span> ');
			this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
		})
	},
  methods: {
		filterlabel(e){
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				return newe
    },
		returnhtml(){
			let data = {
				Welcome:this.filterlabel(document.querySelector('.edit-box').innerHTML),
				fileList:this.fileList
			}
			return data
		},
    insertWxNickName() {
				document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#客户微信昵称#</span> ';
				var html = document.querySelector('.edit-box').innerHTML;
				this.length = this.filterlabel(html).length
				// this.$emit('gethtml',html)
		},
		insertEmployeeName() {
				document.querySelector('.edit-box').innerHTML += ' <span style="color: #409EFF;">#员工姓名#</span> ';
				var html = document.querySelector('.edit-box').innerHTML;
				this.length = this.filterlabel(html).length
				// this.$emit('gethtml',html)
		},
    checkLength() {
				var html = document.querySelector('.edit-box').innerHTML;
				// html = html.replace('#客户微信昵称#', '').replace('#员工姓名#', '');
				this.length = this.filterlabel(html).length
				// console.log(document.querySelector('.edit-box').innerHTML)
			},
    editBlur() {
				var html = document.querySelector('.edit-box').innerHTML;
				if (html.length > 1000) {
					document.querySelector('.edit-box').innerHTML = html.substr(0, 1000);
				}
				this.length = this.filterlabel(document.querySelector('.edit-box').innerHTML).length
				// this.$emit('gethtml',document.querySelector('.edit-box').innerHTML)
		},
    handleEditFile(record, index){
				this.editIndex = index;
				if (record.Type == 2){
					this.miniVisible = true;
					this.editData = record;
				}else if (record.Type == 3){
					this.cardVisible = true;
					this.editData = record;
				}
			},
    beforeUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;

				const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
					'image/webp'
				]
				if (testtype.indexOf(file.type) == -1) {
					this.$message.error('请选择图片上传');
					return false
				} else if (!isLt2M) {
					this.$message.error('图片大小请控制在2M以内')
					return false
				}
				return true;
			},
    handleUploadSuccess(res, file, index) {
				var obj = {
					Type: 1, //1 图片类型
					LinkName: file.name,
					ImgUrl: res[0]
				}
				
				if (index == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(index, 1, obj)
				}
				
				this.showPop = false;
			},
      handleDeleteFile(index) {
				this.fileList.splice(index, 1)
			},
    handleAddLink(){
				this.editIndex = -1;
				this.miniVisible = true;
				this.editData = {};
			},
			handleAddMiniProgram(){
				this.editIndex = -1;
				this.cardVisible = true;
				this.editData = {};
			},
		miniChange(record) {
				
				var obj = {
					Type: 2, //2 链接
					LinkName: record.name,
					ImgUrl: record.imgUrl,
					LinkUrl: record.url,
					Description: record.desc
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				this.$emit('getfilelist',this.fileList)
			},
		cardChange(record) {
				
				var obj = {
					Type: 3, //3 小程序
					LinkName: record.name,
					LinkUrl: record.url,
					Description: record.desc,
					ImgUrl: record.imgUrl,
				}
				
				if (this.editIndex == -1){
					this.fileList.push(obj)
				}else{
					this.fileList.splice(this.editIndex, 1, obj)
				}
				this.$emit('getfilelist',this.fileList)
			},
  }
}
</script>

<style lang = "less" scoped>
.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}
			.example{
				flex: 0 0 auto;
				
				img{
					display: block;
					width: 350px;
				}
			}

		.tips-box {
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			font-size: 14px;
			line-height: 24px;
			color: #606266;

			display: flex;

			.tit {
				flex: 0 0 auto;
				width: 50px;
			}

			.cont {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}

		.employee-list {
			margin-left: 10px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.employee-item {
				display: flex;
				align-items: center;
				height: 26px;
				margin-right: 10px;
				margin-bottom: 5px;
				margin-top: 5px;
				padding: 0 5px;
				font-size: 12px;
				border: 1px solid #ddd;
				border-radius: 3px;

				.e-hd {
					width: 18px;
					height: 18px;
					overflow: hidden;
					border-radius: 2px;
					margin-right: 5px;
					background-color: #F6F6F6;
				}

				.e-no-hd {
					display: inline-block;
					width: 18px;
					height: 18px;
					line-height: 18px;
					text-align: center;
					font-size: 12px;
					color: #fff;
					border-radius: 2px;
					vertical-align: 1px;
					background-color: #409eff;
					margin-right: 5px;
				}

				.e-delete {
					margin-left: 5px;
					font-size: 14px;
					cursor: pointer;
				}


			}

			.show-more-box {
				transform: rotate(-90deg);
				margin-top: 2px;
				cursor: pointer;
				margin-right: 4px;
			}

			.show-more {
				font-size: 16px;
				color: #999;

			}
		}

		.edit-wraper {
			border: 1px solid #ddd;
			background-color: #FBFDFF;
			line-height: 18px;
			border-radius: 4px;
			
			position: relative;

			.title {
				padding: 15px 15px;
				display: flex;
				border-bottom: 1px dashed #bbb;
			}

			.num {
				position: absolute;
				right: 10px;
				top: 254px;
				color: #999;
			}

			.link-wraper {
				padding: 15px;
				border-top: 1px solid #ddd;


			}

			.file-list {
				overflow: hidden;
				.file-item {
					display: flex;
					align-items: center;
					overflow: hidden;
					margin-bottom: 10px;
					// background: #cccbcb;
					// padding: 5px 10px;
					color: #666;
					font-size: 12px;

					>img {
						flex: 0 0 auto;
						width: 20px;
						margin-right: 5px;
					}
					
					.type{
						flex: 0 0 auto;
						width: 60px;
					}

					.txt {
						flex: 1 1 auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						// max-width: 80%;
					}
					.control{
						flex: 0 0 auto;
						margin-left: 10px;
						display: flex;
						
					}
					.edit {
						cursor: pointer;
						font-size: 16px;
					
						display: flex;
						align-items: center;
					}
					.close {
						margin-left: 5px;
						cursor: pointer;
						font-size: 16px;

						display: flex;
						align-items: center;
					}
				}
			}
		}

		.edit-box {
			height: 230px;
			padding: 15px;
			box-sizing: border-box;
			overflow: auto;
		}

  .control-wraper {
		display: flex;

		.item {

			width: 60px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			cursor: pointer;

			img {
				width: 24px;
				margin-bottom: 4px;
			}
		}
	}
</style>