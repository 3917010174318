<template>
  <div>
    <el-dialog :title="title" :visible="Visible" @close = "closeddialog" width="70%">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class = "flexRow">
          <div class = "keyFont">关键字：</div>
          <el-input v-model="keywords" style="width:180px" placeholder="员工姓名、手机号"></el-input>
        </div>
        <div class = "flexRow" style="margin:0px 15px">
          <div class = "keyFont">归属门店：</div>
          <el-select v-model="stores" style="width:200px" placeholder="请选择归属门店">
            <el-option :value="null" label="全部"></el-option>
            <el-option v-for="(v,i) in storeslist" :key="i" :value="v.Id" :label="v.ShopName"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="querystaff">查询</el-button>
      </div>
      <div style="margin-top:10px">
        <el-table :data="tablelist" v-loading = "tableloading" ref="stafftable" :row-key = "getrowkey"
         @selection-change="handleSelectionChange" max-height="500px">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column prop="EmployeeName" label="员工">
            <template slot-scope="scope">
              <div class = "flexRow">
                <img :src="scope.row.WxHeadUrl?scope.row.WxHeadUrl:defaultheader" style="width:60px;height:60px;border-radius:3px" alt="">
                <div class = "vertical2" style="margin-left:5px">
                  {{scope.row.EmployeeName}}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Phone" label="手机号"></el-table-column>
          <el-table-column prop="ShopName" label="归属门店"></el-table-column>
        </el-table>
      </div>
      <div class = "flexRow" style="justify-content: space-between;margin-top:10px">
        <el-checkbox :value="allcheck" style="margin-left:15px" @change="allcheckchange">当前页全选</el-checkbox>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="sizepage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div class = "flexRow" style="justify-content: center;margin-top:30px">
        <div>已选中 <span style="color:#f56c6c">{{checklist.length}}</span> 个</div>
        <el-button type="primary" style="width:90px;height:36px;margin-left:10px" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  shopdroplist
} from "@/api/api"
import {
  qyWeixinContactMeapplicableemployeelist
} from "@/api/sv1.0.0"
import config from "@/config"
export default {
  props: {
    Id:{
      type:[Number,String],
      default:0
    },
    Visible:{
      type:Boolean,
      default:false,
    },
    title:{
      type:String,
      default:'选择适用员工'
    },
    incomingchecklist:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data () {
    return {
      defaultheader:config.DEFAULT_HEADER,
      keywords:'',
      stores:null,
      storeslist:[],
      tablelist:[],
      alltablelist:[],
      tableloading:false,
      currentPage:1,
      sizepage:10,
      total:null,
      checklist:[],
      isfirst:false,
    }
  },
  computed:{
    allcheck(){
      if(this.tablelist&&this.tablelist.length){
        return this.tablelist.every((v)=>{
          return this.checklist.some((x)=>{
            return x.Id==v.Id
          })
        })
      }else{
        return false
      }
    }
  },
  watch:{
    Visible(val){
      if(val){
        // this.checklist = this.incomingchecklist
        this.getshopdroplist()
        this.isfirst = true
        this.gettablelist()
        // this.$nextTick(()=>{
        //   this.checklist.map((v)=>{
        //     this.$refs.stafftable.toggleRowSelection(v,true)
        //   })
        // })
      }
    }
  },
  methods: {
    querystaff(){
      this.currentPage = 1
      this.gettablelist()
    },
    confirm(){
      // if(this.checklist.length>100){
      //   this.$message.error('每场活动最多选择100个员工')
      // }else{
        this.$emit('confirm',this.checklist)
      // }
    },
    allcheckchange(e){
      // console.log(e)
      this.$refs.stafftable.toggleAllSelection()
      // console.log(this.checklist.length)
      //此时全选的列表还没有被赋值 所以加上当前页的数量
      if(e){
        if(this.checklist.length+this.tablelist.length>100){
          this.$message.error('每场活动最多选择100个员工')
        }
      }else{
        if(this.checklist.length-this.tablelist.length>100){
          this.$message.error('每场活动最多选择100个员工')
        }
      }
      
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.separatelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.separatelist()
    },
    async gettablelist(){
      this.tableloading = true
      try{
        let data = {
          Id:this.Id,
          Keywords:this.keywords,
          ShopId:this.stores
        }
        let result = await qyWeixinContactMeapplicableemployeelist(data)
        if(result.IsSuccess){
          // console.log(result)
          this.alltablelist = result.Result
          this.separatelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    separatelist(){
      this.total = this.alltablelist.length
      let start = (this.currentPage-1)*this.sizepage
      let end = this.currentPage*this.sizepage
      this.tablelist = this.alltablelist.slice(start,end)
      if(this.isfirst){
        this.$nextTick(()=>{
          // console.log(this.incomingchecklist,'incomingchecklist')
          this.checklist = this.incomingchecklist.filter((v)=>{
             let a= this.alltablelist.filter((x)=>{
              if(v.Id==x.Id){
                // console.log(v,'v')
                this.$refs.stafftable.toggleRowSelection(x,true)
                return v
              }
            })
            // console.log(a)
            return a&&a.length?a:''
          })
          // console.log(this.alltablelist.find((v)=>{return v.Id==1225}))
          // console.log(this.checklist,'checklist')
          this.isfirst = false
        })
      }
    },
    handleSelectionChange(e){
      // console.log(e)
      this.checklist = e
      if(e.length>100){
        this.$message.error('每场活动最多选择100个员工')
      }
    },
    getrowkey(row){
      return row.Id
    },
    closeddialog(){
      this.$emit('close')
      this.keywords = ''
      this.stores = null
      this.currentPage = 1
      this.sizepage = 10
      this.checklist = []
      this.$refs.stafftable.clearSelection()
    },
    //门店列表
    async getshopdroplist(){
      const res = await shopdroplist({
					IsOpen: true
				});
				if (res.IsSuccess) {
          // console.log(res.Result)
					this.storeslist = res.Result;
				}
    },  
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    /* justify-content: space-between; */
  }
  .keyFont{
    font-size: 14px;
    font-weight: bold;
    word-break: normal;
    color: #606266;
  }
  .vertical2{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    word-break: break-all;
  }
</style>