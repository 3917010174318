<template>
<div>
  <creatsuccess :msg="showtype" @recovery="recovery">
    <div v-loading = "pageloading">
      <el-form :model="ruForm" :rules="rules" ref="ruForm" label-width="130px" style="margin-bottom:100px">
        <el-card>
          <div class = "headline">员工活码</div>
          <div style="margin-top:20px">
            <el-form-item prop="Remark" label="备注：" class = "remarkError">
              <el-input v-model="ruForm.Remark" placeholder="请输入备注" style="width:300px" maxlength="15"></el-input>
              <div class = "garyFont">给活码设置备注，便于后续管理查找</div>
            </el-form-item>
            <choosestaff :EmployeeList = "ruForm.EmployeeList" :EmployeeListrules = "rules.EmployeeList"
            @confirm = "getlist" :Id="$route.query.Id"></choosestaff>
            <el-form-item label="优先锁粉店员：">
              <el-switch v-model="ruForm.IsLockMallEmployeePriority"></el-switch>
              <div class = "garyFont">开启后，客户在商城内点击配置了活码的入口时，系统优先生成客户锁粉店员的企微二维码，无论锁粉店员是否在已选中的适用员工列表</div>
            </el-form-item>
          </div>
        </el-card>
        <el-card style="margin-top:10px">
          <div class = "headline">添加好友欢迎语</div>
          <div style="margin-top:20px">
            <el-form-item label="欢迎语：">
              <el-switch v-model="ruForm.IsOpenWelcome"></el-switch>
              <div class = "garyFont">1.开启后，客户在商城内通过活码添加员工企微好友，将会发送此处配置的欢迎语</div>
              <div class = "garyFont">2.若关闭，始终发送好友欢迎语中，员工适用的欢迎语</div>
              <div v-if="ruForm.IsOpenWelcome">
                <welcomelanguage style="max-width:1000px" ref="welcome" :Welcome="ruForm.Welcome" :fileList="ruForm.AttachmentInfoList" :rule="rules.Welcome"
                @gethtml = "gethtml" @getfilelist = "getfilelist"></welcomelanguage>
              </div>
            </el-form-item>
          </div>
        </el-card>
        <el-card style="margin-top:10px">
          <div class = "headline">更多设置</div>
          <div style="margin-top:20px">
            <el-form-item label="客户标签：">
              <el-tag v-for="(v,i) in ruForm.TagDetailList" :key="v.Id" type="info" effect="plain" style="margin:0px 10px 5px 0px"
              closable @close="handleClose(i)">{{v.Name}}</el-tag>
              <el-button type="text" @click="tagdialogTag=true">选择标签</el-button>
              <div class = "garyFont">给通过活码添加员工企业微信的客户打上的标签</div>
            </el-form-item>
          </div>
        </el-card>
        <el-card class="bottomCard" style="padding-left:5%">
          <el-button @click="$router.push({path:'/qyCustomer/callme'})">取消</el-button>
          <el-button type="primary" @click="save('ruForm')">保存</el-button>
        </el-card>
      </el-form>


      <!-- 打标签 -->
      <tagging :dialogTag="tagdialogTag" :selected="ruForm.TagDetailList" @close="tagdialogTag=false" @confirm="confirmtag" :tagkey="'Id'"
      :title="'选择标签'"></tagging>
    </div>
  </creatsuccess>
</div>
</template>

<script>
import choosestaff from "./components/choosestaff/index"
import welcomelanguage from "./components/welcomelanguage/index"
import tagging from "./components/addtag/tagging"
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
import{
  qyWeixinContactMeInfoRequest,
  qyWeixinContactMeSaveRequest,
} from "@/api/sv1.0.0"
export default {
  components:{
    choosestaff,
    welcomelanguage,
    tagging,
    creatsuccess
  },
  data () {
    var checkWelcome = (rule, value, callback) => {
      let testnull = /^ [\s]*$/
      // console.log(!testnull.test(value))
      if(!this.filterlabel(value)||testnull.test(value)){
        callback(new Error('欢迎语文本内容不可为空'))
      }else{
        callback()
      }
    }
    return {
      pageloading:false,
      ruForm:{
        Remark:'',
        EmployeeList:[],
        IsLockMallEmployeePriority:false,
        IsOpenWelcome:false,
        Welcome:'',
        AttachmentInfoList:[],
        TagDetailList:[]
      },
      rules:{
        Remark:[
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],
        EmployeeList:[
          { required: true, message: '请选择适用员工', trigger: 'blur' },
        ],
        Welcome:[
					{ required: true, message: '欢迎语文本内容不可为空', trigger: 'blur' },
          { required: true, validator: checkWelcome, trigger: 'blur' },
				]
      },
      tagdialogTag:false,

      showtype:{
        issuccess:false,
        id:0,
        type:0,
        suctype:9,
      }
    }
  },
  created () {
    // console.log(this.$route.query.Id)
    if(this.$route.query.Id!=0){
      this.getdetailmsg()
    }
  },
  methods: {
    recovery(){
      this.$router.push({path:this.$route.path,query:{
        Id:0
      }})
      this.showtype.issuccess = false
      this.ruForm = {
        Remark:'',
        EmployeeList:[],
        IsLockMallEmployeePriority:false,
        IsOpenWelcome:false,
        Welcome:'',
        AttachmentInfoList:[],
        TagDetailList:[]
      }
    },
    //标签
    handleClose(i){
      this.ruForm.TagDetailList.splice(i,1)
    },
    confirmtag(taglist){
      this.ruForm.TagDetailList = taglist.map((v)=>{
        v.Id=v.TagDetailId
        return v
      })
      console.log(this.ruForm.TagDetailList)
      this.tagdialogTag = false
    },
    //欢迎语
    gethtml(html){
      this.ruForm.Welcome = html
      // console.log(this.ruForm.Welcome)
    },
    getfilelist(list){
      this.ruForm.AttachmentInfoList = list
    },
    //适用员工
    getlist(list){
      this.ruForm.EmployeeList = list
    },
    save(formName){
              // console.log(this.ruForm.Welcome,987)
      // if(this.ruForm.IsOpenWelcome){
      //   console.log(this.$refs.welcomelanguage.returnhtml())
      // }
      if(this.ruForm.IsOpenWelcome){
        this.ruForm.Welcome = this.$refs.welcome.returnhtml().Welcome
      }
      // console.log(this.ruForm.Welcome)
      this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            this.tosave()
          } else {
            if(!this.ruForm.EmployeeList||!this.ruForm.EmployeeList.length){
              this.$message.error('请选择适用员工')
            }else if(this.ruForm.IsOpenWelcome&&!this.ruForm.Welcome){
              this.$message.error('欢迎语文本内容不可为空')
            }else{
              this.$message.error('请完善配置')
            }
            this.$nextTick(()=>{
              let iserror = document.getElementsByClassName('is-error')
              iserror[0].scrollIntoView({
                block:'center',
                behavior:'smooth'
              })
            })
            return false;
          }
        });
    },
    filterlabel(e){
			// console.log(e)
				let str  = JSON.parse(JSON.stringify(e))
				let newe = str.replace(/<[^<>]+>/g,'')
				// console.log(newe)
				return newe
    },
    async tosave(){
      this.pageloading = true
      try{
        let data = {QyWeixinContactMeSaveDto:JSON.parse(JSON.stringify(this.ruForm))}
        data.QyWeixinContactMeSaveDto.Id = this.$route.query.Id?data.QyWeixinContactMeSaveDto.Id:0
        if(data.QyWeixinContactMeSaveDto.IsOpenWelcome){
          data.QyWeixinContactMeSaveDto.Welcome = this.$refs.welcome.returnhtml().Welcome
         }else{
          data.QyWeixinContactMeSaveDto.Welcome = ''
          data.QyWeixinContactMeSaveDto.AttachmentInfoList = []
        }
        data.QyWeixinContactMeSaveDto.EmployeeList = data.QyWeixinContactMeSaveDto.EmployeeList.map((v)=>{
          return v.Id
        })
        data.QyWeixinContactMeSaveDto.TagDetailList = data.QyWeixinContactMeSaveDto.TagDetailList.map((v)=>{
          return v.Id
        })
        let result = await qyWeixinContactMeSaveRequest(data)
        if(result.IsSuccess){
          this.$message.success('保存成功')
          if(this.$route.query.Id!=0){
            this.$router.push({
              path:'/qyCustomer/callme'
            })
          }else{
            // console.log(result)
            this.showtype = {
              issuccess:true,
              id:result.Result.Id,
              // type:this.$route.query.activeType,
              suctype:9,
            }
          }
        }
      }finally{
        this.pageloading = false
      }
    },
    async getdetailmsg(){
      this.pageloading = true
      try{
        let data = {
          Id:this.$route.query.Id
        } 
        let result = await qyWeixinContactMeInfoRequest(data)
        if(result.IsSuccess){
          // console.log(result)
          this.ruForm = result.Result
          this.ruForm.Welcome = this.ruForm.Welcome.replace(/#客户微信昵称#/g, ' <span style="color: #409EFF;">#客户微信昵称#</span> ').replace(/#员工姓名#/g, ' <span style="color: #409EFF;">#员工姓名#</span> ') 
          // console.log(this.ruForm.Welcome)
        }
      }finally{
        this.pageloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display:flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
  }
  .headline{
    font-size: 16px;
    /* color: #606266; */
    /* font-weight: bold; */
  }
  .garyFont{
    font-size: 12px;
    color: #999999;
    line-height: 2;
  }
  .bottomCard{
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .blueBox{
    border:1px solid #409EFF;
    background: #FBFDFF;
    padding: 10px;
    margin: 15px 0px;
    width: 700px;
    display: flex;
    flex-direction: row;
  }
  .blueBox div{
    color: #606266;
    font-size: 14px;
    line-height: 1.7;
  }
</style>
<style>
</style>